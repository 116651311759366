.header__wrapper {
    background-color: rgba(255, 255, 255, .7);
    backdrop-filter: blur(10px);
    position: fixed;
    top: 0;
    width: 100vw;
    z-index: 10;
}


.header__content--wrapper {
    width: 66vw;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
}

.header__menu--wrapper {
    display: flex;
    flex-direction: column;
    font-family: roboto-condensed;
    gap: 1rem;
    font-weight: 400;
    font-size: 2rem;
    margin: 2rem 0 2rem 0;
}

.header__menu--logo {
    width: 12vw;
}

.header__menu--wrapper > a {
    color: #414241;
    text-align: right;
}

.header__menu--link {
    text-decoration: none;
    color: inherit;
}

.selected {
    text-decoration: underline;
    color: #909339 !important;
}

.header__menu--link:hover {
    text-decoration: underline;
}

.header__menu--link:visited {
    color: inherit;
}


.header__menu--hamburger {
    fill: #909339;
}


@media only screen
and (max-width: 950px)
and (min-width: 550px) {
    .header__wrapper {
        font-size: 15px;
    }

    .header__content--wrapper {
        width: unset;
        margin: 2rem 3rem;
    }


    .header__menu--logo {
        width: 20vw;
    }

    .header__menu--wrapper {
        flex-direction: row;
        font-size: 1.5rem;
    }

}

@media only screen
and (max-width: 550px) {
    .header__menu--logo {
        width: 10vw;
    }

    .header__wrapper {
        height: 15vw;
        display: grid;
        grid-column: 1;
        grid-row: 1;
        font-size: 1.5rem;
    }

    .header__content--wrapper {
        width: unset;
        margin: 0 2rem 0 2rem;
        grid-area: 1;
        height: 15vw;
    }

    .header__menu--hamburger {
        width: 8vw;
    }

    .header__links--wrapper {
        z-index: 2;
        grid-column: 1;
        grid-row: 2;
        justify-self: flex-end;

        width: 33vw;
        display: flex;
        flex-direction: column;
        text-align: right;

        background-color: rgba(255, 255, 255, .7);
        backdrop-filter: blur(10px);
        -webkit-backdrop-filter: blur(10px);
        margin-top: 2rem;
        margin-right: 1rem;
        padding: 1rem;

        transition: .5s ease-in-out;
        transform: translateX(25rem);
    }

    .header__menu--link {
        margin-bottom: 1rem;
    }

    .header__menu--title{
        font-size: 2rem;
        color: #909339;
        font-family: "roboto-condensed", sans-serif;
        font-weight: 600;
    }

}
