@font-face{
  font-family: "roboto-condensed";
  src: url(./assets/fonts/RobotoCondensed-Light.ttf);
  font-weight: 300;
}

@font-face{
  font-family: "roboto-condensed";
  src: url(./assets/fonts/RobotoCondensed-Regular.ttf);
  font-weight: 400;
}

@font-face{
  font-family: "roboto-condensed";
  src: url(./assets/fonts/RobotoCondensed-Bold.ttf);
  font-weight: 700;
}

@font-face{
  font-family: "roboto-condensed";
  src: url(./assets/fonts/RobotoCondensed-LightItalic.ttf);
  font-weight: 300;
  font-style: italic;
}

@font-face{
  font-family: "roboto-condensed";
  src: url(./assets/fonts/RobotoCondensed-Italic.ttf);
  font-weight: 400;
  font-style: italic;
}

@font-face{
  font-family: "roboto-condensed";
  src: url(./assets/fonts//RobotoCondensed-BoldItalic.ttf);
  font-weight: 700;
  font-style: italic;
}

html,
body {
  font-size: 62.5%;
  font-family: sans-serif;
  overflow-x: hidden;
  background-color: #E1E1E1;

}

p {
  line-height: 2.5rem;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  max-width: 100% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
