.expertise__root--wrapper{
  display: flex;
  flex-direction: column;
  font-family: 'Roboto-Condensed', sans-serif;
}

.expertise__header--wrapper{
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.header__wrapper{
  grid-column: 1;
  grid-row: 1;
}

.expertise__header--overlay{
  grid-column: 1;
  grid-row: 1;
  width: 100%;
  height: 100%;

  z-index: 2;
  background-color: rgba(225, 225, 225, 1);
  background: linear-gradient(
    0deg,
    rgba(225, 225, 225, 1) 0%,
    rgba(225, 225, 225, .5) 30%,
    rgba(225, 225, 225, 0) 35%,
    rgba(225, 225, 225, 0) 90%,
    rgba(225, 225, 225, .9) 100%
  );

}

.expertise__header--image{
  grid-row: 1;
  grid-column:1;

  width: 100vw;
  
  height: auto;
}

.expertise__main--wrapper{
  z-index: 3;
  width: 66vw;
  margin: -20rem auto 0 auto
}

.expertise__main--title{
  font-size: 4rem;
  font-weight: 700;
  color: #909339;
  margin-bottom: .5rem;
}

.expertise__wrapper{
  width: 50%;
}

.expertise__wrapper > p, li {
  font-size: 2rem;
  font-weight: 300;
}

h2{
  font-size: 2rem;
  font-weight: 700;
}

.expertise__wrapper > p {
  margin-bottom: 1rem;
}

.expertise__list--wrapper{
  background-color: #909339;
  background: linear-gradient(
    180deg, #909339 0%, 
    rgba(144, 147, 57, .80) 100%);
  color: #E1E1E1;
  width: 80%;
  margin: 3rem;
  padding: 3rem;
}

.expertise__item--wrapper{
  margin-bottom: 3rem;
}

.expertise__list{
  list-style: none;
  margin-left: 3rem;
}

.expertise__list--item::before{
  content: "- ";
}

@media only screen
and (max-width: 950px){
  .expertise__main--wrapper{
    width: unset;
    margin: auto 2rem;
    margin-top: unset;
  }

  .expertise__wrapper{
    width: unset;
  }

  .expertise__main--title{
    font-size: 3rem;
  }

  .expertise__wrapper > p, li{
    font-size: 1.5rem;
  }

  .expertise__list--wrapper{
    width: 70%;
    justify-self: flex-end;
    margin: 3rem 0 3rem auto; 
  }


}

@media only screen
and (max-width: 550px){
  .expertise__main--wrapper{
    width: 100vw;
    margin: unset
  }

  .expertise__main--title{
    margin-left: 2rem;
  }

  .expertise__wrapper > p {
    margin: 2rem;
  }

  .expertise__list--wrapper{
    width: unset;
    padding: 2rem;
  }

  .expertise__item--wrapper{
    margin-bottom: 2rem;
  }

  .expertise__list{
    margin-left: 2rem;
  }
}
