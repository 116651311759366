.homepage__main--wrapper {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    background-color: #E1E1E1;
}

.homepage__header--wrapper {
    top: 0;
}

.homepage__video--wrapper {
    display: grid;
    overflow: hidden;

    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
}

.homepage__video {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    z-index: 1;
    pointer-events: none;
}

.homepage__video--overlay {
    grid-row: 1;
    grid-column: 1;
    width: 100%;

    z-index: 2;
    background-color: rgba(0, 0, 0, .2)
}

.homepage__video--text {
    grid-row: 1;
    grid-column: 1;

    align-self: center;
    padding: 20vw;

    z-index: 3;
    font-size: 5rem;
    color: white;
    text-align: center;
    font-style: italic;
    font-weight: bold;
    line-height: 5.5rem;
}

.homepage__intro--wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;

}

.homepage__intro--content {
    grid-row: 1;
    grid-column: 1;
    z-index: 3;
    width: 66vw;
    margin: 3rem auto auto;

}

.homepage__intro--background {
    grid-row: 1;
    grid-column: 1;
    width: 100vw;
    z-index: 1;
    margin-top: 15rem;

}

.homepage__intro--title {
    font-size: 4rem;
    color: #909339;
    font-family: "roboto-condensed", sans-serif;
    font-weight: bold;
    margin-bottom: .5rem;
}

.homepage__intro--body {
    font-size: 2rem;
    font-family: "roboto-condensed", sans-serif;
    font-weight: 300;
    margin-bottom: 1rem;
    width: 100%;
}

.homepage__intro--overlay {
    grid-row: 1;
    grid-column: 1;
    z-index: 2;
    height: 60%;
    width: 100%;

    background: rgb(225, 225, 225);
    background: linear-gradient(180deg, 
        rgba(225, 225, 225, 1) 0%, 
        rgba(225, 225, 225, 1) 20%, 
        rgba(225, 225, 225, 0) 100%);
}

@media only screen and 
(max-width: 1400px){
    .homepage__intro--content{
        grid-row: 1;
        z-index: 3;
    }

    .homepage__intro--background{
        margin-top: -20rem;
        grid-row: 2;
        z-index: 0;
    }
    
    .homepage__intro--overlay{
        grid-row: 2;
        margin-top: -20rem;
        z-index: 1;
    }


}

@media only screen and
(max-width: 950px) {

    .homepage__video {
        width: unset;
        margin: auto;
        height: 65vh;
    }

    .homepage__video--text {
        max-width: 100vw;
        padding: unset;
        font-size: 3rem;
    }

    .homepage__intro--content {
        width: unset;
        margin: 0 3rem 0 3rem;
    }

    .homepage__intro--title {
        font-size: 3rem;
    }

    .homepage__intro--body {
        font-size: 1.5rem;
    }
}

@media only screen and
(max-width: 550px) {

    .homepage__video {
        margin: auto;
    }

    .homepage__video--text {
        padding: unset;
    }

    .homepage__intro--wrapper {
        display: flex;
        flex-direction: column;
    }

    .homepage__intro--overlay {
        display: none;
    }

    .homepage__intro--content {
        margin: 2rem;
    }

    .homepage__intro--body {
        width: unset;
    }

    .homepage__intro--background {
        margin: unset;
    }
}




