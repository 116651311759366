.homepage__footer--wrapper {
    padding: 2rem 0 2rem 0;
    background-color: #E1E1E1;
}

.homepage__footer--text {
    font-size: 2rem;
    font-family: 'roboto-condensed', sans-serif;
    font-weight: bolder;
    text-align: center;
}

@media only screen and (max-width: 950px){
    .homepage__footer--text{
        font-size: 1.5rem;
    }

    .homepage__footer--wrapper{
        padding: 1.5rem 0 1.5rem 0;
    }
}
