.contact__root--wrapper{
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.contact__content--wrapper{
  width: 66vw;
  margin: 18rem auto 3rem auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  font-family: 'roboto-condensed', arial;
}

.contact__content--header{
  font-size: 4rem;
  color: #909339;
  font-weight: 700
}

.contact__content--text{
  font-size: 2rem;
  width: 48%;
}

.contact__content--text > p {
  margin-bottom: 2rem;
  font-weight: 300;
}

.contact__content--text * strong{
  font-weight: bold;
  color: #909339;
}

strong > a{
  color: inherit
}

.contact__image--wrapper{
  width: 100vw;
  height: 80vh;
  overflow: hidden;
  background: url('../assets/contact/contact.webp');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

@media only screen
and (max-width: 950px){
  .contact__content--wrapper{
    width: unset;
    margin-top: 12rem;
    flex-direction: column;
  }

  .contact__content--header{
    font-size: 3rem;
  }

  .contact__content--text{
    font-size: 1.5rem;
    margin: 0 2rem 2rem 2rem;
    width: unset;
  }

  .contact__content > p{
    margin-bottom: 1rem;
  }

  .contact__image--wrapper{
    display: none;
  }
}

