.team__main--wrapper {
    width: 100vw;
    background-color: #E1E1E1;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
    font-family: "roboto-condensed", sans-serif;
    transition: .3s all;
}

.team__header--wrapper {
    display: flex;
    flex-direction: column;
}

.header__image {
    width: 100vw;
}

.team__spotlight--wrapper {
    width: 66vw;
    margin: 3rem auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    transition: .3s ease-in-out;
}

.spotlighted__name {
    font-size: 4rem;
    color: #909339;
    font-family: 'roboto-condensed', sans-serif;
    font-weight: 600;
    margin-bottom: 1rem;
}

.spotlighted__mailto--wrapper, .lawyer__mailto--wrapper {
    border: #909339 3px solid;
    text-decoration: none;
    display: flex;
    background-color: #909339;
    justify-content: center;
    align-items: center;
    gap: .5rem;
    width: fit-content;
    padding: 1rem;

    transition: .3s all;
    margin: 1rem 0 1rem 0;
}

.spotlighted__mailto--wrapper:hover {
    cursor: pointer;
    background-color: transparent;
}

.spotlighted__mailto--wrapper:hover > .spotlighted__mailto--text {
    color: #909339;
}

.spotlighted__mailto--icon {
    width: 2.5rem;
}

.spotlighted__mailto--text {
    color: #E1E1E1;

    font-size: 2rem;
    font-family: roboto-condensed, sans-serif;
}

.lawyer__description {
    font-size: 2rem;
    font-family: "roboto-condensed", sans-serif;
    font-weight: 300;
    width: 55%;
    display: inline-block;
}

.lawyer__description > p {
    margin-bottom: 1rem;
}

.team__search--field {
    font-family: "roboto-condensed", sans-serif;
    font-size: 2rem;
    padding: 1rem;
    height: fit-content;
    background: none;
    border: 3px solid #909339;
    color: #909339;
    text-decoration: underline;
}

.team__search--field:focus {
    border: 3px solid #909339;
}

.team__search--field::placeholder {
    color: #909339;
    font-weight: 900;

}

.search__suggestion--wrapper {
    width: 66vw;
    margin: 6rem auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.search__suggestion--text {
    color: #909339;
    font-size: 2rem;
    font-weight: 500;
}

.search__suggestion--arrow {
    width: 4rem;
}

.team__grid--wrapper {
    background-image: url("../assets/team/grid__background.png");
    background-position: center;
    background-size: cover;
}

.lawyer__grid{
    width: 66vw;
    margin: 3rem auto 3rem auto;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    transition: .2s ease-in;
}

.lawyer__grid--section{
    max-width: 75%;
    display: flex;
    flex-direction: row;
    gap: 3rem;

}

.lawyer__grid--section:nth-child(even){
    align-self: center;
}

.lawyer__grid:nth-child(even){
    align-self: flex-start;
}

.lawyer__wrapper {
    overflow: hidden;
    opacity: .8;
    background-blend-mode: difference;
    transition: .3s all;
    display: grid;
    width: 30%;
}

.lawyer__image{
    grid-row: 1;
    grid-column: 1;
    transition: .3s all;
    width: 100%
}

.lawyerSelected > .lawyer__overlay{
    opacity: unset;
    display: none;
}

.lawyerSelected{
    transition: .2s ease-in-out;
    transform: translateY(-150px);
    opacity: unset;
}



.lawyer__overlay{
    grid-row: 1;
    grid-column: 1;
    background-color: #909339;
    opacity: .5;
    transition: .3s ease-in-out;
}

.lawyer__wrapper:hover > .lawyer__overlay{
    display: none;
    cursor: pointer
}


.lawyer__wrapper:hover{
    cursor: pointer;
}

@media only screen 
and (max-width: 950px){
    .team__spotlight--wrapper{
        width: unset;
        margin: 3rem;
    }

    .lawyer__description{
        width: 80%;
        font-size: 1.5rem;
    }

    .spotlighted__name{
        font-size: 3rem !important;
    }

    .spotlighted__mailto--wrapper{
        padding: .5rem;
    }

    .search__suggestion--wrapper{
        margin-bottom: 3rem;
    }

    .search__suggestion--arrow{
        width: 2rem;
    }

    .team__main--wrapper * p {
        font-size: 1.5rem;
        font-weight: 400
    }

    .lawyer__grid{
        width: unset;
        margin: 3rem;
        
    }
}

@media only screen
and (max-width: 550px){
    .header__image{
        margin-top: 15vw;
    }

    .lawyer__list--wrapper{
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin: 2rem;
        font-family: 'roboto-condensed', sans-serif;
    }

    .lawyer__head--wrapper{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 2rem;
    }

    .lawyer__head--text{
        width: 50%;
        align-self: center;
        display: flex;
        flex-direction: column;
        gap: 2rem;
    }

    .lawyer__item--wrapper:nth-child(even) > .lawyer__head--wrapper{
        flex-direction: row-reverse;
    }
    
    .lawyer__text--description{
        margin-top: 2rem;
        font-size: 1.5rem;
        font-family: 'roboto-condensed', sans-serif;
        width: 100%;
    }

    .lawyer__description{
        width: 100%;
    }

    .lawyer__image{
        max-width: 50%;
    }

    .lawyer__text--name{
        font-size: 3rem;
        font-weight: bold;
        color: #909339;
    }

    .lawyer__mailto--wrapper{
        border: none;
        padding: .8rem;
    }

    .lawyer__mailto--icon{
        display: none;
        width: 15%;
        margin: none;
    }

    .lawyer__mailto--text {
        font-size: 1.5rem;
        color: #E1E1E1;
        font-weight: 600;
        text-decoration: underline;
    }
}
